import "./assets/main.css";

import "./axios";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Notifications from '@kyvg/vue3-notification'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

//createApp(App).use(store).use(router).mount('#app')
import {
  faCar,
  faCalendar,
  faClock,
  faArrowUpRightFromSquare,
  faCaretUp,
  faPlus,
  faLocationArrow,
  faUser,
  faUserGroup,
  faRoad,
  faBookBookmark,
  faRightFromBracket,
  faPause,
  faLocation,
  faSearch,
  faRefresh,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

//Cada icono debe ser añadido e importado
library.add(
  faCar,
  faCalendar,
  faClock,
  faArrowUpRightFromSquare,
  faCaretUp,
  faPlus,
  faLocationArrow,
  faUser,
  faUserGroup,
  faRoad,
  faBookBookmark,
  faRightFromBracket,
  faPause,
  faLocation,
  faSearch,
  faRefresh,
  faTrash,
  faCheck
);

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(store);
app.use(Notifications);

app.mount("#app");
